<template>
    <div class="c-intro-text -left" :class="[{'is-active': isActive}, modifiers]">
        <div class="o-container">
            <div class="o-grid -col-12 -gutter -center-items-y">
                <div class="u-gc-1/13 u-gc-3/11@from-small">
                    <div class="c-intro-text_inner">
                        <text-reveal>
                            <p class="c-intro-text_text || u-anim-text" :class="{'is-inview': isInview}" v-html="text"></p>
                        </text-reveal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { normalize } from 'gsap/all';
import TextRevealVue from '../blocks/TextReveal.vue';
export default {
    name: "IntroTextCenter",
    components: {
        "text-reveal": TextRevealVue,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        modifiers: {
            type: String,
            default: ''
        },
        progress: {
            type: Number,
            required: true
        },
        progressStart: {
            type: Number,
            required: true
        },
        progressEnd: {
            type: Number,
            required: true
        },
        inviewStart: {
            type: Number,
            default: 0.1
        },
        inviewEnd: {
            type: Number,
            default: 0.9
        },
    },
    computed: {
        isActive() {
            return this.progress >= this.progressStart && this.progress < this.progressEnd
        },
        normalizedProgress() {
            return normalize(this.progressStart, this.progressEnd, this.progress)
        },
        isInview() {
            return this.normalizedProgress >= this.inviewStart && this.normalizedProgress < this.inviewEnd
        }
    }
};
</script>