/* eslint-disable */
import * as THREE from 'three'
import anime from 'animejs'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { Pola } from "./entities/pola";
import GLTexts from "./gltexts";
import Lights from "./lights";
import MatFactory from "./mat-factory";
import TextureProvider from "./lib/texture-provider";
import CamCtrl from './CamCtrlPola';
import { Gauje } from './ui/gauje'
import { Mouse } from './lib/Mouse';

import { seconds } from 'lol/js/promise/time';

let now = Date.now()
let lastTime = now

export class ScenePola {

  constructor() {

    this.isPaused = false
    this.isLoaded = false

    this.dt = 0

    // renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer = renderer

    renderer.domElement.classList.add('cvs_pola')

    // scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);

    // cam
    this.camera = new THREE.PerspectiveCamera(45, window.innerHeight / window.innerHeight, 0.01, 100)
    this.camera.position.set(0, 0.5, 7);

    // inputs
    this.mouse = new Mouse()
    this.mouse.start()

    // lights
    this.lights = new Lights(this.scene)

    // materials
    this.mats = null
    this.texs = null
    this.envmap = null

    // entities
    this.scene = new THREE.Scene()
    this.pola = new Pola(this)
    this.gltexts = new GLTexts(this)
    this.gauje = new Gauje(this)


    this.bind()

    this.gauje.events.faded.on(this.transitionOut)

  }

  bind() {
    this.onRAF = this.onRAF.bind(this)
    this.transitionOut = this.transitionOut.bind(this)
  }

  load() {

    return new Promise(async (resolve) => {

      await this.loadEnv()
      await this.loadTexs()
      await this.pola.load()

      this.onLoaded()

      resolve()

    })

  }

  loadEnv() {

    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();

    return new Promise((resolve) => {
      const loader = new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load('/texs/envs/studio_small_07_512.hdr', (texture) => {

          this.envMap = pmremGenerator.fromEquirectangular(texture).texture;

          texture.dispose();
          pmremGenerator.dispose();

          resolve()

        })

    })

  }

  loadTexs() {

    const matNames = [
      'BACK_SCREEN',
      'BLACK_METAL',
      'CENTER_WHEEL',
      'CENTER_WHEEL_STRIPES',
      'GLASS_FLASH',
      'LEATHER_HANDLE',
      'METAL_BODY_MAIN',
      'METAL_BODY_UP_RING',
      'PHOTO_TOUCH',
      'POLA_EXIT_PLATE',
      'REFLECTOR_FLASH',
      'SCREEN',
      'TIMER_KEY',
      'WHEEL_LOCK'
    ]

    const manifest = []
    matNames.forEach(matName => {
      manifest.push(
        { id: `${matName}_BaseColor`, url: `/texs/pola/1k/${matName}_BaseColor.png` },
        { id: `${matName}_Roughness`, url: `/texs/pola/1k/${matName}_Roughness.png` },
        { id: `${matName}_Normal`, url: `/texs/pola/1k/${matName}_Normal.png` },
        { id: `${matName}_Metalness`, url: `/texs/pola/1k/${matName}_Metallic.png` },
      )
    })

    manifest.push({ id: 'text_polahd', url: "/texs/POLAHD.png" })
    manifest.push({ id: 'text_mark2', url: "/texs/MarkII.png" })
    manifest.push({ id: 'text_hereismy', url: "/texs/here_is_my.png" })
    manifest.push({ id: 'text_takeit', url: "/texs/take_it.png" })

    this.texs = new TextureProvider()

    return new Promise(async (resolve, reject) => {
      await this.texs.load(manifest)
      resolve()
    })

  }

  onLoaded() {

    this.mats = new MatFactory(this)
    this.camCtrl = new CamCtrl(this)

    this.pola.onSceneLoaded()
    this.gltexts.onSceneLoaded()

    this.onResize()

    this.transitionIn()

    this.isLoaded = true

    this.onRAF()

  }

  transitionIn() {

    // this.pola.transitionIn()
    // this.camCtrl.transitionIn()
    // this.gltexts.transitionIn()
    // this.gauje.transitionIn()

  }

  async transitionOut() {
    this.gauje.events.faded.off(this.transitionOut)

    this.camCtrl.transitionOut()
    this.pola.transitionOut()
    this.gltexts.hideTakeText()
    this.gauje.transitionOut()

    // this.gauje.hasTransitionned = false
    setTimeout(() => {
      this.onTransitionnedOut()
    }, 4000)

  }

  // callback to call from parent
  onTransitionnedOut() { }

  playIntro() {
    this.pola.playIntro()
    this.camCtrl.playIntro()
    this.gltexts.playIntro()
  }

  fadeOutTexts() {
    this.gltexts.fadeOut()
  }

  updateIntroProgress(progress) {
    this.pola.updateIntroProgress(progress)
    this.camCtrl.updateIntroProgress(progress)
    this.gltexts.updateIntroProgress(progress)
  }

  showGauje() {
    this.gltexts.hideIntroTexts()
    this.gltexts.showTakeText()
    this.gauje.transitionIn()

  }

  onRAF() {
    requestAnimationFrame(this.onRAF)

    if (this.isPaused) return

    now = Date.now()
    this.dt = (now - lastTime) / 1000
    lastTime = now

    this.render()
  }

  render() {
    this.camCtrl.update()
    this.gauje.update(this.dt)
    // this.imgplayer.update(this.dt)

    this.renderer.render(this.scene, this.camera);

  }

  onResize() {

    const camera = this.camera
    const renderer = this.renderer

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

  }

}