/* eslint-disable */
import * as THREE from 'three'
import gui from "./dev/gui"
import { map } from './utils/math'
import anime from 'animejs'

import { gsap } from 'gsap'
import { TweenMax } from "gsap/gsap-core"
import { Power4 } from "gsap/all"

const cfg = {
  text1: {
    position: { x: -.67, y: 0.15, z: 4 },
    scale: 1.2
  },
  text2: {
    position: { x: 40, y: -0.05, z: -5 },
    scale: 3.70
  },
  text3: {
    position: { x: -1.02, y: 0.37, z: 4 },
    scale: .5
  },
  text4: {
    position: { x: 0, y: 0.15, z: 4 },
    scale: .93
  }
}

const INTRO_START_PROGRESS = .45

class GLTexts {

  constructor(scene) {

    this.scene = scene

    this.playedIntro = false
    this.isFadeOut = false
  }

  onSceneLoaded() {
    const texs = this.scene.texs

    // POLA HD
    const tText1 = texs.get('text_polahd')
    tText1.flipY = true
    let r = 975 / 178
    let geom = new THREE.PlaneBufferGeometry(1, 1 / r)
    let mat = new THREE.MeshBasicMaterial({ map: tText1, transparent: true, opacity: 0 })
    const text1 = new THREE.Mesh(geom, mat)
    text1.position.x = cfg.text1.position.x
    text1.position.z = cfg.text1.position.z
    let s = 2
    text1.scale.set(s, s, s)
    this.scene.scene.add(text1)

    // MARK II
    const tText2 = texs.get('text_mark2')
    tText2.flipY = true
    r = 788 / 195
    geom = new THREE.PlaneBufferGeometry(1, 1 / r)
    mat = new THREE.MeshBasicMaterial({ map: tText2, transparent: true, opacity: 0 })
    const text2 = new THREE.Mesh(geom, mat)
    text2.position.z = cfg.text2.position.z
    text2.position.x = cfg.text2.position.x
    s = 2
    text2.scale.set(s, s, s)
    this.scene.scene.add(text2)

    // HERE IS MY
    let tex = texs.get('text_hereismy')
    tex.flipY = true
    r = 460 / 90
    geom = new THREE.PlaneBufferGeometry(1, 1 / r)
    mat = new THREE.MeshBasicMaterial({ map: tex, transparent: true, opacity: 0 })
    const text3 = new THREE.Mesh(geom, mat)
    text3.position.x = cfg.text3.position.x
    text3.position.z = cfg.text3.position.z
    s = 2
    text3.scale.set(s, s, s)
    this.scene.scene.add(text3)

    // TAKE IT
    tex = texs.get('text_takeit')
    tex.flipY = true
    r = 688 / 186
    geom = new THREE.PlaneBufferGeometry(1, 1 / r)
    mat = new THREE.MeshBasicMaterial({ map: tex, transparent: true, opacity: 0 })
    const text4 = new THREE.Mesh(geom, mat)
    text4.position.x = cfg.text4.position.x
    text4.position.z = cfg.text4.position.z
    s = 2
    text4.scale.set(s, s, s)
    this.scene.scene.add(text4)

    this.text1 = text1
    this.text2 = text2
    this.text3 = text3
    this.text4 = text4

    this.gui()
  }

  gui() {

    this.updateFromCfg = this.updateFromCfg.bind(this)

    const f = gui.addFolder({ title: 'texts', expanded: false })

    const f1 = f.addFolder({ title: `text1`, expanded: false })
    f1.addInput(cfg.text1.position, 'x', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f1.addInput(cfg.text1.position, 'y', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f1.addInput(cfg.text1.position, 'z', { min: -5, max: 5, step: .01 }).on('change', this.updateFromCfg)
    f1.addInput(cfg.text1, 'scale', { min: .2, max: 5, step: .01 }).on('change', this.updateFromCfg)

    const f2 = f.addFolder({ title: `text2`, expanded: false })
    f2.addInput(cfg.text2.position, 'x', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f2.addInput(cfg.text2.position, 'y', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f2.addInput(cfg.text2.position, 'z', { min: -5, max: 5, step: .01 }).on('change', this.updateFromCfg)
    f2.addInput(cfg.text2, 'scale', { min: .2, max: 5, step: .01 }).on('change', this.updateFromCfg)

    const f3 = f.addFolder({ title: `text3`, expanded: false })
    f3.addInput(cfg.text3.position, 'x', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f3.addInput(cfg.text3.position, 'y', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f3.addInput(cfg.text3.position, 'z', { min: -5, max: 5, step: .01 }).on('change', this.updateFromCfg)
    f3.addInput(cfg.text3, 'scale', { min: .2, max: 5, step: .01 }).on('change', this.updateFromCfg)

    const f4 = f.addFolder({ title: `text4`, expanded: false })
    f4.addInput(cfg.text4.position, 'x', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f4.addInput(cfg.text4.position, 'y', { min: -2, max: 2, step: .01 }).on('change', this.updateFromCfg)
    f4.addInput(cfg.text4.position, 'z', { min: -5, max: 5, step: .01 }).on('change', this.updateFromCfg)
    f4.addInput(cfg.text4, 'scale', { min: .2, max: 5, step: .01 }).on('change', this.updateFromCfg)


    this.updateFromCfg()

  }

  updateFromCfg() {

    this.text1.position.x = cfg.text1.position.x
    this.text1.position.y = cfg.text1.position.y
    this.text1.position.z = cfg.text1.position.z
    this.text1.scale.set(cfg.text1.scale, cfg.text1.scale, cfg.text1.scale)

    // this.text2.position.x = cfg.text2.position.x
    this.text2.position.y = cfg.text2.position.y
    this.text2.position.z = cfg.text2.position.z
    this.text2.scale.set(cfg.text2.scale, cfg.text2.scale, cfg.text2.scale)

    this.text3.position.x = cfg.text3.position.x
    this.text3.position.y = cfg.text3.position.y
    this.text3.position.z = cfg.text3.position.z
    this.text3.scale.set(cfg.text3.scale, cfg.text3.scale, cfg.text3.scale)

    this.text4.position.x = cfg.text4.position.x
    this.text4.position.y = cfg.text4.position.y
    this.text4.position.z = cfg.text4.position.z
    this.text4.scale.set(cfg.text4.scale, cfg.text4.scale, cfg.text4.scale)

  }

  transitionIn() {

    const animCfg = {
      text1: {
        opacity: 0,
        x: cfg.text1.position.x - .2
      },
      text2: {
        opacity: 0,
        x: cfg.text2.position.x + .7
      }
    }

    // text1
    this.text1.material.opacity = 0
    this.text1.position.y = cfg.text1.position.y
    anime({
      targets: animCfg.text1,
      x: cfg.text1.position.x,
      opacity: 1,
      duration: 4000,
      delay: 2600,
      easing: 'easeOutQuint',
      update: () => {
        this.text1.position.x = animCfg.text1.x
        this.text1.material.opacity = animCfg.text1.opacity
      }
    })


    // text2
    this.text2.position.y = cfg.text2.position.y
    this.text2.material.opacity = 0
    anime({
      targets: animCfg.text2,
      x: cfg.text2.position.x + .5,
      opacity: 1,
      duration: 4000,
      delay: 3200,
      easing: 'easeOutQuint',
      update: () => {
        this.text2.position.x = animCfg.text2.x
        this.text2.material.opacity = animCfg.text2.opacity
      }
    })

  }

  transitionOut() {

    const animCfg = {
      text1: {
        opacity: 1,
        y: cfg.text1.position.y
      },
      text2: {
        opacity: 1,
        y: cfg.text2.position.y
      }
    }

    // text1
    anime({
      targets: animCfg.text1,
      y: cfg.text1.position.y - .3,
      opacity: 0,
      duration: 2300,
      delay: 0,
      easing: 'easeInOutQuart',
      update: () => {
        this.text1.position.y = animCfg.text1.y
        this.text1.material.opacity = animCfg.text1.opacity
      }
    })


    // text2
    anime({
      targets: animCfg.text2,
      y: cfg.text2.position.y + .3,
      opacity: 0,
      duration: 2300,
      delay: 0,
      easing: 'easeInOutQuart',
      update: () => {
        this.text2.position.y = animCfg.text2.y
        this.text2.material.opacity = animCfg.text2.opacity
      }
    })

  }

  updateIntroProgress(progress) {

    this.updateIntroIn(progress)

  }

  playIntro() {

    if (this.playedIntro) return

    this.playedIntro = true


    const animProps = {
      t1: 0,
      t2: 0,
      t3: 0
    }

    TweenMax.to(animProps, 2, {
      t1: 1,
      onUpdate: () => {
        this.text1.position.z = gsap.utils.mapRange(0, 1, cfg.text1.position.z + .25, cfg.text1.position.z, animProps.t1)
        this.text1.material.opacity = animProps.t1
      }
    })

    TweenMax.to(animProps, 2, {
      t2: 1,
      delay: 1,
      onUpdate: () => {
        this.text2.position.x = gsap.utils.mapRange(0, 1, cfg.text2.position.x + .7, cfg.text2.position.x + .5, animProps.t2)
        this.text2.position.z = gsap.utils.mapRange(0, 1, cfg.text2.position.z + .25, cfg.text2.position.z, animProps.t2)
        this.text2.material.opacity = animProps.t2
      }
    })


    TweenMax.to(animProps, 1, {
      t3: 1,
      delay: 1.8,
      onUpdate: () => {
        this.text3.position.x = gsap.utils.mapRange(0, 1, cfg.text3.position.x - 0.1, cfg.text3.position.x, animProps.t3)
        this.text3.material.opacity = animProps.t3
      }
    })

  }

  fadeOut() {

    if (this.isFadeOut) return

    this.isFadeOut = true

    TweenMax.killTweensOf(this.text1.material)
    TweenMax.killTweensOf(this.text1.position)
    TweenMax.killTweensOf(this.text2.material)
    TweenMax.killTweensOf(this.text2.position)
    TweenMax.killTweensOf(this.text3.material)
    TweenMax.killTweensOf(this.text3.position)

    TweenMax.to(this.text1.material, .5, { opacity: 0 })
    TweenMax.to(this.text1.position, .5, { y: this.text1.position.y - .05, ease: Power4.easeIn })

    TweenMax.to(this.text2.material, .5, { opacity: 0, delay: .1, ease: Power4.easeIn })
    TweenMax.to(this.text2.position, .5, { x: this.text2.position.x + .2, delay: .1, ease: Power4.easeIn })

    TweenMax.to(this.text3.material, .5, { opacity: 0, delay: .2, ease: Power4.easeIn })
    TweenMax.to(this.text3.position, .5, { x: this.text3.position.x - .2, delay: .2, ease: Power4.easeIn })

  }

  updateIntroIn(anim) {
    // console.log(progress)
    // let t1progress = gsap.utils.mapRange(INTRO_START_PROGRESS + .05, INTRO_START_PROGRESS + .2, 0, 1, progress)
    // t1progress = gsap.utils.clamp(0, 1, t1progress)

    let t1progress = anim.t1
    let t2progress = anim.t2

    // let t2progress = gsap.utils.mapRange(INTRO_START_PROGRESS + .15, INTRO_START_PROGRESS + .25, 0, 1, progress)
    // t2progress = gsap.utils.clamp(0, 1, t2progress)

    // let t3progress = gsap.utils.mapRange(INTRO_START_PROGRESS, INTRO_START_PROGRESS + .05, 0, 1, progress)
    // t3progress = gsap.utils.clamp(0, 1, t3progress)

    // this.text1.position.x = gsap.utils.mapRange(0, 1, cfg.text1.position.x - 0.05, cfg.text1.position.x - .1, t1progress)
    this.text1.position.z = gsap.utils.mapRange(0, 1, cfg.text1.position.z + .25, cfg.text1.position.z, t1progress)
    this.text1.material.opacity = t1progress

    this.text2.position.x = gsap.utils.mapRange(0, 1, cfg.text2.position.x + .7, cfg.text2.position.x + .5, t2progress)
    this.text2.position.z = gsap.utils.mapRange(0, 1, cfg.text2.position.z + .25, cfg.text2.position.z, t2progress)
    this.text2.material.opacity = t2progress

    // this.text3.position.x = gsap.utils.mapRange(0, 1, cfg.text3.position.x - 0.1, cfg.text3.position.x, t3progress)
    // this.text3.material.opacity = t3progress

  }

  hideIntroTexts() {

    TweenMax.to(this.text1.material, 1, { opacity: 0 })
    TweenMax.to(this.text1.position, 1, { y: this.text1.position.y - .05, ease: Power4.easeIn })

    TweenMax.to(this.text2.material, 1, { opacity: 0, delay: .1, ease: Power4.easeIn })
    TweenMax.to(this.text2.position, 1, { x: this.text2.position.x + .2, delay: .1, ease: Power4.easeIn })

    TweenMax.to(this.text3.material, 1, { opacity: 0, delay: .2, ease: Power4.easeIn })
    TweenMax.to(this.text3.position, 1, { x: this.text3.position.x - .2, delay: .2, ease: Power4.easeIn })


  }

  showTakeText() {

    console.log(this.text4.position.z)
    this.text4.position.z = 4.5

    TweenMax.to(this.text4.material, 3, { opacity: 1, ease: Power4.easeOut, delay: 2 })
    TweenMax.to(this.text4.position, 3, { z: 4, ease: Power4.easeOut, delay: 2 })

  }

  hideTakeText() {

    TweenMax.to(this.text4.material, 2, { opacity: 0, ease: Power4.easeIn, delay: .2 })
    TweenMax.to(this.text4.position, 2, { z: this.text4.position.z + .5, ease: Power4.easeIn, delay: .2 })

  }

}

export default GLTexts