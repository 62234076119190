/* eslint-disable */
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import anime from 'animejs'
import { gsap, TweenMax } from 'gsap'

// import gui from "../../dev/gui"

// const cfg = {

//   pola: {
//     main: {
//       rotation: { x: 0, y: 0, z: 0.55 }
//     },
//     rxNode: {
//       rotation: { x: 0, y: 0, z: 0 }
//     },
//     ryNode: {
//       rotation: { x: 0, y: -1, z: 0 }
//     }
//   }
// }


export class Pola {

  constructor(scene) {
    this.scene = scene

    this.gltf = null

    this.node = new THREE.Object3D()
    this.rxNode = new THREE.Object3D()
    this.ryNode = new THREE.Object3D()

    this.rxNode.add(this.ryNode)
    this.node.add(this.rxNode)
    // this.node.rotateY(-Math.PI/4)

    this.playedIntro = false

    const scale = 2
    this.node.scale.set(scale, scale, scale)

    this.children = []

  }

  load() {

    return new Promise((resolve) => {

      const loader = new GLTFLoader().setPath('/models/pola/');
      loader.load('pola.glb', (gltf) => {
        this.gltf = gltf
        this.ryNode.add(gltf.scene);
        resolve()
      });

    })

  }

  onSceneLoaded() {

    const mats = this.scene.mats

    this.gltf.scene.traverse((child) => {
      if (child.type == 'Mesh') {
        const materialName = child.material.name
        const m = mats.get(materialName)
        child.material = m
        this.children.push(child)
      }
    })

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].material.envMapIntensity = 0.005
      this.children[i].material.needsUpdate = true
    }

    this.scene.scene.add(this.node)

    // this.transitionIn()

    this.gui()

  }

  transitionIn() {

    // anim light intensity
    const anim = {
      envIntensity: 0
    }

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].material.envMapIntensity = 0
      this.children[i].material.needsUpdate = true
    }

    anime({
      targets: anim,
      envIntensity: 0.05,
      duration: 5000,
      delay: 1000,
      easing: 'easeOutQuart',
      update: () => {
        for (let i = 0; i < this.children.length; i++) {

          this.children[i].material.envMapIntensity = anim.envIntensity
          this.children[i].material.needsUpdate = true
        }
      }
    })

  }

  transitionOut() {

    // anim light intensity
    const anim = {
      envIntensity: 0.05
    }

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].material.envMapIntensity = 0.05
      this.children[i].material.needsUpdate = true
    }

    anime({
      targets: anim,
      envIntensity: 0,
      duration: 3000,
      delay: 1000,
      easing: 'easeInOutQuart',
      update: () => {
        for (let i = 0; i < this.children.length; i++) {
          this.children[i].material.envMapIntensity = anim.envIntensity
          this.children[i].material.needsUpdate = true
        }
      }
    })

  }

  playIntro() {

    if (this.playedIntro) return

    this.playedIntro = true

    const animProps = {
      lightIntensity: 0.005
    }

    const onPlayingIntro = () => {
      for (let i = 0; i < this.children.length; i++) {
        this.children[i].material.envMapIntensity = animProps.lightIntensity
        this.children[i].material.needsUpdate = true
      }
    }

    TweenMax.to(animProps, 2, {
      lightIntensity: 0.05,
      onUpdate: onPlayingIntro
    })

  }



  updateIntroProgress(progress) {

    let polaProgress = gsap.utils.mapRange(0.3, 0.8, 0, 1, progress)
    polaProgress = gsap.utils.clamp(0, 1, polaProgress)

    const lightIntensity = gsap.utils.mapRange(0, 1, 0.005, 0.05, polaProgress)
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].material.envMapIntensity = lightIntensity
      this.children[i].material.needsUpdate = true
    }
  }




  gui() {
    // const fPola = gui.addFolder({title:'pola', expanded: false})

    // const fPolaMain = fPola.addFolder({title:`main node`, expanded: false})
    // fPolaMain.addInput(cfg.pola.main.rotation, 'x', {min: -Math.PI, max: Math.PI, step: .01})
    // fPolaMain.addInput(cfg.pola.main.rotation, 'y', {min: -Math.PI, max: Math.PI, step: .01})
    // fPolaMain.addInput(cfg.pola.main.rotation, 'z', {min: -Math.PI, max: Math.PI, step: .01})

    // const fPolaRx = fPola.addFolder({title:`rx node`, expanded: false})
    // fPolaRx.addInput(cfg.pola.rxNode.rotation, 'x', {min: -Math.PI, max: Math.PI, step: .01})

    // const fPolaRy = fPola.addFolder({title:`ry node`, expanded: false})
    // fPolaRy.addInput(cfg.pola.ryNode.rotation, 'y', {min: -Math.PI, max: Math.PI, step: .01})


  }

}
