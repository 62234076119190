<template>
    <div class="o-scroll-trigger" data-scroll-section>
        <div
            class="o-scroll-trigger_inner"
            data-scroll
            :data-scroll-id="'scrollTrigger-' + uid"
            data-scroll-offset="100%, 100%"
        >
            <div
                :id="'sticky-' + uid"
                class="o-scroll-trigger_area"
                aria-hidden="true"
            ></div>
            <div
                class="o-scroll-trigger_sticky"
                data-scroll
                data-scroll-sticky
                :data-scroll-target="'#sticky-' + uid"
            >
                <p>{{progress}}</p>
                <slot :progress="progress" :uid="uid"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { uuidv4 } from "../../utils/uuid";
import { mapActions, mapState } from "vuex";

export default {
    name: "ScrollTrigger",
    data() {
        return {
            uid: uuidv4(),
        };
    },
    computed: {
        ...mapState("main", ["isFontsLoaded"]),
        ...mapState("metrics", ["width"]),
        progress() {
            return this.$store.getters["scroll/getScrollProgressByUid"](
                this.uid
            );
        },
    },
    watch: {
        isFontsLoaded: "onFontsLoaded",
        width: "onResize",
    },
    created() {
        this.setScrollTriggerInstance({
            uid: this.uid,
            progress: 0,
        });
    },
    mounted() {},
    methods: {
        ...mapActions("main", ["forceScrollUpdate"]),
        ...mapActions("scroll", ["setScrollTriggerInstance"]),

        // Events
        onFontsLoaded() {
            this.update();
            this.computeHeight();
        },

        onResize() {
            this.update();
            this.computeHeight();
        },

        update() {
            this.$nextTick(() => {
                this.forceScrollUpdate;
            });
        },

        computeHeight() {
            const views = this.$el.querySelectorAll('.js-scroll-trigger-view')
            const heights = [];
            const reducer = (previousValue, currentValue) => previousValue + currentValue;

            let viewIndex = 0
            while (viewIndex < views.length) {
                const view = views[viewIndex]
                heights.push(view.offsetHeight)
                viewIndex++
            }

            const result = heights.length && heights.reduce(reducer)

            const totalHeight = result ? result : 0
            this.$el.style.height = `${totalHeight}px`
        }
    },
};
</script>