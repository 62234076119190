<template>
    <div class="c-intro-bullets" :class="{'is-active': isActive}" :style="heightVar">
        <div class="o-container">
            <div class="o-grid -col-12 -gutter -center-items-y">
                <div class="u-gc-1/13 u-gc-3/11@from-small">
                    <div class="c-intro-bullets_inner">
                        <div class="c-intro-bullets_item" v-for="(text, index) in texts" :key="index">
                            <text-reveal>
                                <p 
                                    class="c-intro-bullets_item_text || u-anim-text" 
                                    :class="{'is-inview': progressBullets >= (1 / texts.length) * index}"
                                >{{ text }}</p>
                            </text-reveal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { normalize } from 'gsap/all';
import TextRevealVue from '../blocks/TextReveal.vue';
export default {
    name: "IntroBullets",
    components: {
        "text-reveal": TextRevealVue,
    },
    props: {
        texts: {
            type: Array,
            required: true,
        },
        progress: {
            type: Number,
            required: true
        },
        progressStart: {
            type: Number,
            required: true
        },
        progressEnd: {
            type: Number,
            required: true
        },
        inviewStart: {
            type: Number,
            default: 0.1
        },
        inviewEnd: {
            type: Number,
            default: 0.9
        },
    },
    computed: {
        isActive() {
            return this.normalizedProgress >= 0 && this.normalizedProgress < 1
        },
        normalizedProgress() {
            return normalize(this.progressStart, this.progressEnd, this.progress)
        },
        isInview() {
            return this.normalizedProgress >= this.inviewStart && this.normalizedProgress < this.inviewEnd
        },
        heightVar() {
            return { "--height": this.texts.length * 100 + 'vh' };
        },
        progressBullets() {
            return normalize(0, (1 / this.texts.length * this.texts.length), this.normalizedProgress)
        },
    }
};
</script>