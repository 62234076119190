<template>
  <div
    class="c-intro-window"
    :class="{
      'is-active': isActive,
      'is-window-small': isWindowSmall,
      'is-window-medium': isWindowMedium,
      'is-window-wide': isWindowWide,
      'is-window-show': isWindowShow,
      'is-window-take': isWindowTake,
      'is-canvas-active': progressImages === 1,
    }"
    :style="clipWindowStyles"
    data-scroll
    data-scroll-call="toggleWindow"
    data-scroll-repeat
    data-scroll-offset="100%, 0"
  >
    <div class="c-intro-window_background">
      <div
        class="c-intro-window_background_inner"
        :style="scaleProgressVar"
      ></div>
    </div>

    <div class="c-intro-window_carousel">
      <div class="c-intro-window_carousel_inner || o-container">
        <div
          class="
            c-intro-window_carousel_grid
            ||
            o-grid
            -col-12
            -gutter
            -center-items-y
          "
        >
          <div
            class="
              c-intro-window_carousel_grid_item
              ||
              u-gc-1/13 u-gc-1/5@from-small
            "
          >
            <text-reveal>
              <p
                class="c-intro-window_text || u-anim-text"
                :class="{ 'is-inview': isWindowSmall && progressImages != 1 }"
              >
                I have taken
              </p>
            </text-reveal>
          </div>
          <div class="u-gc-1/13 u-gc-5/9@from-small">
            <div class="c-intro-window_carousel_list" ref="dummyWindow"></div>
          </div>
          <div
            class="
              c-intro-window_carousel_grid_item
              ||
              u-gc-1/13 u-gc-9/12@from-small
            "
          >
            <text-reveal>
              <p
                class="c-intro-window_text || u-anim-text -delay-2"
                :class="{ 'is-inview': isWindowSmall && progressImages != 1 }"
              >
                a few pictures.
              </p>
            </text-reveal>
          </div>
        </div>
      </div>
    </div>

    <!-- Wide dummy -->
    <div class="c-intro-window_wide -dummy">
      <div class="o-container">
        <div class="o-grid -col-12 -gutter -center-items-y">
          <div class="u-gc-1/13 u-gc-3/11@from-small">
            <div class="c-intro-window_wide_dummy" ref="dummyWideWindow"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Clip -->
    <div class="c-intro-window_clip">
      <!-- Carousel -->
      <div
        class="c-intro-window_carousel"
        :style="{
          pointerEvents: pointerEvents(isWindowSmall && !isWindowMedium),
        }"
      >
        <div class="c-intro-window_carousel_inner || o-container">
          <div
            class="
              c-intro-window_carousel_grid
              ||
              o-grid
              -col-12
              -gutter
              -center-items-y
            "
          >
            <div class="u-gc-1/13 u-gc-5/9@from-small">
              <ul
                class="c-intro-window_carousel_list"
                :class="{ 'is-active': progressImages < 1 }"
              >
                <li
                  class="c-intro-window_carousel_item"
                  :class="{
                    'is-active': progressImages >= (1 / images.length) * index,
                  }"
                  v-for="(image, index) in images"
                  :key="index"
                  ref="image"
                >
                  <div class="c-figure">
                    <div class="c-figure_inner">
                      <img class="c-figure_image" :src="image" alt="tmp" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Wide dummy -->
      <div
        class="c-intro-window_wide"
        :style="{
          pointerEvents: pointerEvents(isWindowMedium && !isWindowWide),
        }"
      >
        <div class="o-container">
          <div class="o-grid -col-12 -gutter -center-items-y">
            <div class="u-gc-1/13 u-gc-3/11@from-small">
              <h2>
                <text-reveal>
                  <span
                    class="c-intro-window_wide_title || u-anim-text"
                    :class="{ 'is-inview': isWindowMedium && !isWindowWide }"
                    >Well,</span
                  >
                </text-reveal>
                <text-reveal>
                  <span
                    class="c-intro-window_wide_text || u-anim-text -delay-2"
                    :class="{ 'is-inview': isWindowMedium && !isWindowWide }"
                    >I am saying pictures, but instant-delayed pictures would be
                    more precise.</span
                  >
                </text-reveal>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <!-- Show -->
      <div
        class="c-intro-window_show"
        :style="{ pointerEvents: pointerEvents(isWindowShow && !isWindowTake) }"
      >
        <div class="o-container">
          <div class="o-grid -col-12 -gutter -center-items-y">
            <div class="u-gc-1/13 u-gc-3/11@from-small">
              <h2>
                <!-- <text-reveal>
                  <span
                    class="c-intro-window_show_subtitle || u-anim-text"
                    :class="{ 'is-inview': isWindowShow && !isWindowTake }"
                    >Here is my</span
                  >
                </text-reveal>
                <text-reveal>
                  <span
                    class="c-intro-window_show_title || u-anim-text -delay-1"
                    :class="{ 'is-inview': isWindowShow && !isWindowTake }"
                    >Polahd <em>MarkII</em></span
                  >
                </text-reveal> -->
              </h2>
            </div>
          </div>
        </div>
      </div>

      <!-- Take it -->
      <div
        class="c-intro-window_take"
        :style="{ pointerEvents: pointerEvents(isWindowTake) }"
        data-cursor="hold"
      >
        <div class="o-container">
          <div class="o-grid -col-12 -gutter -center-items-y">
            <div class="u-gc-1/13 u-gc-3/11@from-small">
              <div class="c-intro-window_take_inner">
                <h2>
                  <text-reveal>
                    <span
                      class="c-intro-window_take_title || u-anim-text"
                      :class="{ 'is-inview': isWindowTake }"
                      >Take <em>it</em></span
                    >
                  </text-reveal>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Canvas -->
      <div class="c-intro-window_canvas" ref="canvasContainer"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
const SECTION_PARTS = 6;
import { gsap, TweenMax, Power4 } from "gsap";
import { normalize, clamp } from "gsap/all";
import { mapState } from "vuex";
import TextRevealVue from "../blocks/TextReveal.vue";

import { ScenePola } from "../../webgl/scene_pola";
import { map } from "../../webgl/utils/math";

import Raf from "quark-raf";

export default {
  name: "IntroWindow",
  components: {
    "text-reveal": TextRevealVue,
  },
  data: () => {
    return {
      clipWindowStyles: {},
      images: [
        "https://i.pinimg.com/564x/90/6a/61/906a618e95ea95659938aee057592f59.jpg",
        "https://i.pinimg.com/564x/f2/aa/ee/f2aaeee326f5e9b6154b2357672c9f94.jpg",
        "https://i.pinimg.com/564x/e0/0e/fb/e00efb11f5f67f09fc92a612e2ae4c3c.jpg",
      ],
    };
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    progress: {
      type: Number,
      required: true,
    },
    progressStart: {
      type: Number,
      required: true,
    },
    progressEnd: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState("main", ["isFontsLoaded"]),
    ...mapState("metrics", ["width"]),
    ...mapState("cursor", ["holdIncrement"]),
    isActive() {
      return (
        this.progress >= this.progressStart && this.progress <= this.progressEnd
      );
    },
    isWindowSmall() {
      return this.progressWindowReveal > 0;
    },
    isWindowMedium() {
      return this.progressWell > 0;
    },
    isWindowWide() {
      return this.progressPolahd > 0;
    },
    isWindowShow() {
      return this.progressPolahd > 0;
    },
    isWindowTake() {
      return this.progressTake > 0;
    },
    normalizedProgress() {
      return normalize(this.progressStart, this.progressEnd, this.progress);
    },
    scaleProgressVar() {
      const scale = clamp(
        0,
        1,
        normalize(0, 1 / SECTION_PARTS, this.normalizedProgress)
      );
      return { "--scale": scale };
    },
    progressWindowReveal() {
      return clamp(
        0,
        1,
        normalize(1 / SECTION_PARTS, 3 / SECTION_PARTS, this.normalizedProgress)
      );
    },
    progressWell() {
      return clamp(
        0,
        1,
        normalize(3 / SECTION_PARTS, 4 / SECTION_PARTS, this.normalizedProgress)
      );
    },
    progressPolahd() {
      return clamp(
        0,
        1,
        normalize(4 / SECTION_PARTS, 5 / SECTION_PARTS, this.normalizedProgress)
      );
    },
    progressTake() {
      return clamp(
        0,
        1,
        normalize(5 / SECTION_PARTS, 6 / SECTION_PARTS, this.normalizedProgress)
      );
    },
    progressImages() {
      return clamp(
        0,
        1,
        normalize(
          0,
          (1 / (this.images.length + 1)) * this.images.length,
          this.progressWindowReveal
        )
      );
    },
  },
  watch: {
    isFontsLoaded: "onFontsLoaded",
    width: "onResize",
    holdIncrement: "onHoldIncrement",
  },
  mounted() {
    this.clipWindow();

    // scene pola
    this.scenePola = new ScenePola();
    this.scenePola.load().then(this.onPolaSceneLoaded.bind(this));

    this.currentscroll = 0;

    // this.scenePack = new ScenePacks(this.$page.works.edges)
    // this.scenePack.load().then(this.onPackSceneLoaded.bind(this))

    // this.scenePola.onTransitionnedOut = this.onPolaTransitionnedOut.bind(this)

    // this.onWheel = this.onWheel.bind(this)

    // this.$refs.postloadertext.anime(0)

    // this.flashCTA = new Gauje(this.$refs.flashcta, 230)

    Raf.add(this.onRAF);
  },
  methods: {
    // Events
    onFontsLoaded() {
      this.clipWindow();
    },

    onResize() {
      this.clipWindow();
    },

    scrollTo() {
      this.isAutoScroll = true;

      const getNextSibling = (elem, selector) => {
        // Get the next sibling element
        var sibling = elem.nextElementSibling;

        // If there's no selector, return the first sibling
        if (!selector) return sibling;

        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
          if (sibling.matches(selector)) return sibling;
          sibling = sibling.nextElementSibling;
        }
      };

      const $next = getNextSibling(this.$parent.$el, ".o-scroll-trigger");

      this.$parent.$parent.scrollTo({
        target: $next,
        options: {
          offset: window.innerHeight * 0.15,
        },
      });
    },

    onHoldIncrement() {
      this.scrollTo();
    },

    // Styles
    pointerEvents(isActive) {
      return isActive ? "auto" : "none";
    },

    // Compute
    clipWindow() {
      const width = this.$refs.dummyWindow.offsetWidth;
      const height = this.$refs.dummyWindow.offsetHeight;
      const wideWidth = this.$refs.dummyWideWindow.offsetWidth;
      const wideHeight = this.$refs.dummyWideWindow.offsetHeight;
      const wWidth = window.innerWidth;
      const wHeight = window.innerHeight;

      const percentWidth = (1 - width / wWidth) * 0.5;
      const percentHeight = (1 - height / wHeight) * 0.5;

      const percentWidthWide = (1 - wideWidth / wWidth) * 0.5;
      const percentHeightWide = (1 - wideHeight / wHeight) * 0.5;

      this.clipWindowStyles = {
        "--clip-x": `${
          (Math.round((percentWidth + Number.EPSILON) * 100) / 100) * 100
        }%`,
        "--clip-y": `${
          (Math.round((percentHeight + Number.EPSILON) * 100) / 100) * 100
        }%`,
        "--clip-x-wide": `${
          (Math.round((percentWidthWide + Number.EPSILON) * 100) / 100) * 100
        }%`,
        "--clip-y-wide": `${
          (Math.round((percentHeightWide + Number.EPSILON) * 100) / 100) * 100
        }%`,
      };
    },

    onPolaSceneLoaded() {
      this.polaCvs = this.scenePola.renderer.domElement;
      this.$refs.canvasContainer.appendChild(this.polaCvs);
      // this.$el.appendChild(this.scenePola.gauje.cvs);
    },

    onRAF() {
      if (!this.scenePola.isLoaded) return;

      let polaprogress = map(this.progress, 0.65, 0.72, 0, 1);
      polaprogress = Math.max(0, Math.min(polaprogress, 1));

      if (this.progress > 0.65) {
        this.scenePola.playIntro();
      }

      if (this.progress > 0.84) {
        this.scenePola.fadeOutTexts();
      }
      // this.scenePola.updateIntroProgress(polaprogress);

      // this.flashCTA.update(this.dt)
      // this.$refs.progressShutter && this.$refs.progressShutter.draw(this.dt)
      // if (this.flashCTA.progress >= 1 && !this.passedFlash) {
      //   setTimeout(() => {
      //     this.onFlashed()
      //   }, 700)
      // }
    },

    onPolaTransitionnedOut() {
      // this.polaCvs.style.display = 'none'
      // this.$refs.imgPlayerContainer2.style.display = 'block'
      // this.$refs.inner.style.display = 'block'
      // document.body.classList.remove('scrollblocked')
      // this.$refs.scrollheight.style.display = 'none'
      // this.$refs?.locomotiveScroll?.scrollInstance?.destroy()
      // this.currentscroll = 0
      // this.currentscroll2 = 0
      // this.passedIntro1 = true
      // this.updateIntro2()
      // TweenMax.to(this.imgPlayer2, 1.5, {
      //   offsetScroll: 1300,
      //   onUpdate: () => {
      //     this.currentscroll2 = Math.random()
      //     this.updateIntro2()
      //   },
      //   onComplete: () => {
      //     this.setScrollInstance2()
      //   },
      // })
    },
  },
};
</script>