<template>
  <article class="c-page -intro" :class="{ mobile: isMobile }">
    <div class="img-player-container" ref="players"></div>
    <div class="gl-container" ref="glContainer"></div>
    <div class="o-scroll" ref="scroll">
      <scroll-trigger v-slot:default="slotProps">
        <intro-text-center
          class="js-scroll-trigger-view"
          text="It feels good to be here,<br> good to be back."
          :progress="slotProps.progress"
          :progress-start="0"
          :progress-end="0.25"
          :inview-start="0"
        />

        <intro-text-left
          class="js-scroll-trigger-view"
          text="Well, from just a<br> few years from now.<br> I mean, <em>ahead.</em>"
          modifiers="-medium"
          :progress="slotProps.progress"
          :progress-start="0.25"
          :progress-end="0.5"
        />

        <intro-text-center
          class="js-scroll-trigger-view"
          text="I’ll spare the technical details."
          :progress="slotProps.progress"
          :progress-start="0.5"
          :progress-end="0.75"
        />

        <intro-text-center
          class="js-scroll-trigger-view"
          text="I’m here to share what I have seen this time. I mean quite literally"
          :progress="slotProps.progress"
          :progress-start="0.75"
          :progress-end="0.98"
        />
      </scroll-trigger>
      <scroll-trigger v-slot:default="slotProps">
        <intro-window
          class="js-scroll-trigger-view"
          :class="{
            'has-window-background': hasWindowBackground,
          }"
          :progress="slotProps.progress"
          :progress-start="0"
          :progress-end="0.98"
        />
      </scroll-trigger>
      <scroll-trigger v-slot:default="slotProps">
        <intro-bullets
          class="js-scroll-trigger-view"
          :texts="[
            'This is the camera of a time-mover.',
            'Not as clumsy or random as a smartphone.',
            'An elegant camera, for a more civilised age.',
          ]"
          :progress="slotProps.progress"
          :progress-start="0.05"
          :progress-end="0.98"
          :inview-start="0"
        />
      </scroll-trigger>

      <scroll-trigger v-slot:default="slotProps">
        <intro-text-left
          class="js-scroll-trigger-view"
          text="Take a picture"
          :progress="slotProps.progress"
          :progress-start="0.05"
          :progress-end="0.33"
          :inview-start="0"
        />

        <intro-spacing
          class="js-scroll-trigger-view"
          :progress="slotProps.progress"
          :progress-start="0.33"
          :progress-end="0.66"
        />

        <intro-text-left
          class="js-scroll-trigger-view"
          text="It’s helping me keep traces of things I have seen there."
          :progress="slotProps.progress"
          :progress-start="0.66"
          :progress-end="0.98"
        />
      </scroll-trigger>

      <scroll-trigger v-slot:default="slotProps">
        <intro-text-left
          class="js-scroll-trigger-view"
          text="Traces you can actually dive into."
          :progress="slotProps.progress"
          :progress-start="0.05"
          :progress-end="0.98"
          :inview-start="0"
        />
      </scroll-trigger>

      <scroll-trigger v-slot:default="slotProps">
        <intro-text-left
          class="js-scroll-trigger-view"
          text="Look."
          modifiers="-wide"
          :progress="slotProps.progress"
          :progress-start="0.05"
          :progress-end="0.5"
          :inview-start="0"
        />

        <intro-spacing
          class="js-scroll-trigger-view"
          :progress="slotProps.progress"
          :progress-start="0.5"
          :progress-end="0.98"
        />
      </scroll-trigger>
    </div>
  </article>
</template>

<script>
import locomotiveScrollMixin from "../mixins/locomotiveScrollMixin";
import cursorMixin from "../mixins/cursorMixin";

import sanity from "../client";
import { mapActions, mapState } from "vuex";
import { ABOUT_QUERY } from "../core/queries/aboutQuery";

import isMobile from "../utils/isMobile";
import ScrollTriggerVue from "../components/blocks/ScrollTrigger.vue";
import IntroTextCenterVue from "../components/intro/IntroTextCenter.vue";
import IntroWindowVue from "../components/intro/IntroWindow.vue";
import IntroBulletsVue from "../components/intro/IntroBullets.vue";
import IntroTextLeftVue from "../components/intro/IntroTextLeft.vue";
import IntroSpacingVue from "../components/intro/IntroSpacing.vue";

import ImgPlayer from "../lib/imgPlayer";
import { map } from "../webgl/utils/math";

export default {
  name: "Intro",
  mixins: [locomotiveScrollMixin, cursorMixin],
  components: {
    "intro-text-center": IntroTextCenterVue,
    "intro-text-left": IntroTextLeftVue,
    "intro-spacing": IntroSpacingVue,
    "intro-window": IntroWindowVue,
    "intro-bullets": IntroBulletsVue,
    "scroll-trigger": ScrollTriggerVue,
  },
  data() {
    return {
      isMobile: isMobile().phone || isMobile().tablet,
      aboutData: null,
      hasWindowBackground: false,
      hasWindow: false,
    };
  },
  computed: {
    ...mapState("main", ["isPageEntering", "pageLeavingDuration"]),
    ...mapState("metrics", ["width", "height"]),
    ...mapState("scroll", ["currentScroll", "limitScroll"]),
  },

  beforeRouteLeave(to, from, next) {
    this.onPageLeaving(next);
  },
  watch: {
    width: "onResize",
    isPageEntering: "onPageEntering",
    currentScroll: "onCurrentScrollChange",
  },
  created() {
    console.log("intro created");

    // No images to fetch
    this.setImagesLoaded(true);

    // players
    this.players = [];
    const p1 = this.makeImgPlayer("intro", 121, "players");
    const p2 = this.makeImgPlayer("intro2", 401, "players", "jpg");
    this.players.push(p1, p2);

    // Fetch data
    this.fetchData();
  },
  methods: {
    ...mapActions("main", [
      "setImagesLoaded",
      "setDataLoaded",
      "forceScrollUpdate",
    ]),

    async fetchData() {
      // Fetch data
      await sanity.fetch(ABOUT_QUERY).then(
        (aboutData) => {
          this.aboutData = aboutData[0];

          this.$nextTick(() => {
            this.setDataLoaded(true);
          });

          console.log(this.aboutData);
        },
        (error) => {
          console.error(error);
        }
      );
    },

    onCurrentScrollChange() {
      if (this.isMobile) return;
      let p = this.currentScroll / this.limitScroll.y;
      this.updatePlayer1(p);
      this.updatePlayer2(p);
    },

    updatePlayer1(progress) {
      const pStart = 0;
      const pEnd = 0.27;
      let p = map(progress, pStart, pEnd, 0, 1);
      p = Math.min(p, 1);

      if (progress < pStart || progress > pEnd) {
        this.players[0].canvas.style.visibility = "hidden";
      } else {
        this.players[0].setProgress(p);
        this.players[0].canvas.style.visibility = "visible";
      }
    },

    updatePlayer2(progress) {
      const pStart = 0.6;
      const pEnd = 1;
      let p = map(progress, pStart, pEnd, 0, 1);
      p = Math.min(p, 1);

      if (progress < pStart || progress > pEnd) {
        this.players[1].canvas.style.visibility = "hidden";
      } else {
        this.players[1].setProgress(p);
        this.players[1].canvas.style.visibility = "visible";
      }
    },

    onPageEntering(isEntering) {
      if (!isEntering) return;
      console.log("ENTERING ANIMATION HERE");

      this.$nextTick(() => {
        this.forceScrollUpdate();
      });
    },

    onPageLeaving(next) {
      this.setImagesLoaded(false);
      this.setDataLoaded(false);

      // After animation complete
      setTimeout(() => {
        next();
      }, this.pageLeavingDuration);
    },

    makeImgPlayer(folder, nbFrames, container, format = "webp") {
      const imgPlayer = new ImgPlayer({ format });

      const path = `/img/sequences/${folder}/`;
      imgPlayer.load(path, nbFrames).then(() => {
        this.$refs[container].appendChild(imgPlayer.canvas);
        imgPlayer.canvas.style.position = "absolute";
        imgPlayer.canvas.style.top = "0px";
        imgPlayer.canvas.style.left = "0px";
        this.onResize();
      });

      imgPlayer.setScrollEnd(document.body.offsetHeight);

      return imgPlayer;
    },

    onResize() {
      for (let i = 0; i < this.players.length; i++) {
        const player = this.players[i];
        player.resize(this.width, this.height);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-player-container {
  position: sticky;
  top: 0;
  left: 0;
}

.gl-container {
  // display: none;
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;

  canvas {
    position: sticky;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

.o-scroll {
  position: relative;
}
</style>

