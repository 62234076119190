/* eslint-disable */
import { vec2 } from "gl-matrix"
import { clamp, map } from "../utils/math"
import Signal from "../lib/Signal"

import anime from 'animejs'

const V2 = vec2.create()
const V2A = vec2.create()
const V2B = vec2.create()

const offset = vec2.create()

const SIZE = 150 * 1.3
const LINE_WIDTH = 2


const cfg = {
  // gradient: 
}

export class Gauje {

  constructor(scene) {
    this.scene = scene

    this.cvs = document.createElement('canvas')

    this.events = {
      faded: new Signal()
    }

    const cvs = this.cvs
    cvs.style.width = SIZE + 'px'
    cvs.style.height = SIZE + 'px'
    cvs.width = SIZE
    cvs.height = SIZE
    cvs.style.position = 'absolute'
    cvs.style.left = '50vw'
    cvs.style.top = 'calc(50vh + 10rem)'
    cvs.style.pointerEvents = 'none'

    this.bb = new DOMRect()
    this.restPos = vec2.create()

    this.ctx = this.cvs.getContext('2d')

    this.progress = 0
    this.fade = 1

    this.isVisible = false

    this.hasTransitionned = false

  }

  onTouchAdded(touch) {
    this.currentTouch = touch
  }

  update(dt) {
    if (!this.isVisible) return

    if (this.bb.width == 0) {
      this.bb = this.cvs.getBoundingClientRect()
      this.restPos[0] = this.bb.left + SIZE / 2
      this.restPos[1] = this.bb.top + SIZE / 2
    }

    const mouse = this.scene.mouse

    if (mouse.isDown) {
      this.progress += dt
    }
    else {
      this.progress -= dt
    }

    this.progress = clamp(this.progress, 0, 1)

    if (this.progress == 1 && !this.hasTransitionned) {
      this.hasTransitionned = true
      this.transitionOut()
    }

    // amplitude
    const ampl = 15
    V2A[0] = ampl
    V2A[1] = ampl

    // get direction, distance and length
    vec2.sub(V2, mouse.coords, this.restPos)
    const dist = vec2.distance(mouse.coords, this.restPos)
    const len = vec2.length(V2)

    // normalize and scale with amplitude
    vec2.normalize(V2, V2)
    vec2.mul(V2, V2, V2A)

    // smooth time
    // go to offset if in range, otherwise go back to rest pos
    if (dist < 200) {
      offset[0] += (V2[0] - offset[0]) * 0.05
      offset[1] += (V2[1] - offset[1]) * 0.05
      this.cvs.style.cursor = 'pointer'
    }
    else {
      offset[0] += (0 - offset[0]) * 0.1
      offset[1] += (0 - offset[1]) * 0.1
      this.cvs.style.cursor = 'auto'
    }

    this.cvs.style.transform = `translate(${offset[0]}px, ${offset[1]}px) rotate(-90deg)`

    this.draw()


  }

  draw() {

    const ctx = this.ctx

    ctx.lineWidth = LINE_WIDTH

    ctx.clearRect(0, 0, SIZE, SIZE)

    const borderScale = map(this.fade, 0, 1, .7, 1)
    const borderSize = (SIZE / 2 - LINE_WIDTH) * borderScale

    ctx.globalAlpha = 1 - this.fade

    // border
    ctx.save()
    ctx.translate(SIZE / 2, SIZE / 2)
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)'
    ctx.beginPath()
    ctx.arc(0, 0, borderSize, 0, Math.PI * 2)
    ctx.closePath()
    ctx.stroke()
    ctx.restore()

    // middle dot
    ctx.save()
    ctx.translate(SIZE / 2, SIZE / 2)
    ctx.fillStyle = 'white'
    ctx.beginPath()
    ctx.arc(0, 0, 4, 0, Math.PI * 2)
    ctx.closePath()
    ctx.fill()
    ctx.restore()

    // fill
    ctx.save()
    ctx.translate(SIZE / 2, SIZE / 2)
    ctx.strokeStyle = 'rgba(34, 66, 232, .7)'
    ctx.beginPath()
    ctx.arc(0, 0, borderSize, 0, Math.PI * Math.pow(this.progress, 2) * 2)
    // ctx.closePath()
    ctx.stroke()
    ctx.restore()

  }

  transitionIn() {
    this.cvs.style.pointerEvents = 'auto'
    this.isVisible = true
    anime({
      targets: this,
      fade: 0,
      duration: 2000,
      delay: 1000,
      easing: 'easeInOutQuint'
    })
  }

  transitionOut() {
    this.cvs.style.pointerEvents = 'none'
    anime({
      targets: this,
      fade: 1,
      duration: 1000,
      easing: 'easeOutQuart',
      complete: () => {
        this.isVisible = false
      }
    })


    this.events.faded.emit()
  }

}