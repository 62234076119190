/* eslint-disable */
import * as THREE from 'three'
const frag = require('./glsl/meshphysical_frag.glsl').default

// console.log(frag)
class MatFactory {

  constructor(scene) {

    this.scene = scene

    this.texs = scene.texs

    this.mats = []

    this.registerMaterial('BACK_SCREEN', this.makeMaterial('BACK_SCREEN'))
    this.registerMaterial('BLACK_METAL', this.makeMaterial('BLACK_METAL'))
    this.registerMaterial('CENTER_WHEEL', this.makeMaterial('CENTER_WHEEL'))
    this.registerMaterial('CENTER_WHEEL_STRIPES', this.makeMaterial('CENTER_WHEEL_STRIPES'))
    this.registerMaterial('GLASS_FLASH', this.makeMaterial('GLASS_FLASH'))
    this.registerMaterial('LEATHER_HANDLE', this.makeMaterial('LEATHER_HANDLE'))
    this.registerMaterial('METAL_BODY_MAIN', this.makeMaterial('METAL_BODY_MAIN'))
    this.registerMaterial('METAL_BODY_UP_RING', this.makeMaterial('METAL_BODY_UP_RING'))
    this.registerMaterial('PHOTO_TOUCH', this.makeMaterial('PHOTO_TOUCH'))
    this.registerMaterial('POLA_EXIT_PLATE', this.makeMaterial('POLA_EXIT_PLATE'))
    this.registerMaterial('REFLECTOR_FLASH', this.makeMaterial('REFLECTOR_FLASH'))
    this.registerMaterial('SCREEN', this.makeMaterial('SCREEN'))
    this.registerMaterial('TIMER_KEY', this.makeMaterial('TIMER_KEY'))
    this.registerMaterial('WHEEL_LOCK', this.makeMaterial('WHEEL_LOCK'))

  }


  registerMaterial(name, material) {
    this.mats[name] = material
  }

  makeMaterial(name) {

    const tAlbedo = this.texs.get(`${name}_BaseColor`)
    const tNormal = this.texs.get(`${name}_Normal`)
    const tRoughness = this.texs.get(`${name}_Roughness`)
    const tMetalness = this.texs.get(`${name}_Metalness`)


    const m = new THREE.MeshStandardMaterial({
      map: tAlbedo,
      normalMap: tNormal,
      roughnessMap: tRoughness,
      metalness: .9,
      // metalnessMap: tMetalness,
      transparent: false,
      envMap: this.scene.envMap,
      envMapIntensity: .05,
      side: THREE.DoubleSide,
    })

    m.needsUpdate = true

    return m

  }

  get(name) {
    if (this.mats[name])
      return this.mats[name]

    console.error('[MatFactory get] no material registered :', name)
    return null
  }

}

export default MatFactory