<template>
    <div class="c-intro-spacing" :class="{ 'is-active': isActive }"></div>
</template>

<script>
import { normalize } from "gsap/all";
export default {
    name: "IntroSpacing",
    props: {
        progress: {
            type: Number,
            required: true,
        },
        progressStart: {
            type: Number,
            required: true,
        },
        progressEnd: {
            type: Number,
            required: true,
        },
        inviewStart: {
            type: Number,
            default: 0.1,
        },
        inviewEnd: {
            type: Number,
            default: 0.9,
        },
    },
    computed: {
        isActive() {
            return (
                this.progress >= this.progressStart &&
                this.progress < this.progressEnd
            );
        },
        normalizedProgress() {
            return normalize(
                this.progressStart,
                this.progressEnd,
                this.progress
            );
        },
        isInview() {
            return (
                this.normalizedProgress >= this.inviewStart &&
                this.normalizedProgress < this.inviewEnd
            );
        },
    },
};
</script>